<template>
  <div class="container-fluid p-4">
    <h1>Manage Custom Forms</h1>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between pt-4 pb-2">
          <div class="d-flex justify-content-start">
            <router-link
              :to="{ name: 'Custom Create' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-button
                variant="primary"
                size="sm"
                :href="href"
                @click="navigate"
              >
                <i class="fa fa-plus mr-1"></i>Add Form
              </b-button>
            </router-link>
          </div>
          <div class="d-flex justify-content-end w-30 d-inline-block">
            <b-form-input
              id="search_bar"
              size="sm"
              type="text"
              placeholder="Search..."
              v-model="searchTerms"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <!-- Main table element -->
          <b-table
            striped
            :busy="busy"
            :items="formsList"
            :fields="formsFields"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="searchTerms"
            stacked="md"
            responsive
            small
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(name)="row">
              {{ row.item.name | humanizeText }}
            </template>
            <template #cell(modified)="row">
              {{ row.item.modified | timeAgo }}
            </template>
            <template #cell(enable)="row">
              <b-form-checkbox
                v-model="row.item.enable"
                size="lg"
                name="check-button"
                switch
                @change="formStatusHandler(row.item)"
              />
            </template>
            <template #cell(actions)="row">
              <b-button
                variant="secondary"
                size="sm"
                v-b-tooltip.hover
                title="View Form"
                class="mr-1"
                id="show-btn"
                @click="setFormData(row.item.form_data)"
              >
                <i class="fas fa-eye"></i>
              </b-button>

              <b-button
                variant="secondary"
                size="sm"
                v-b-tooltip.hover
                title="Update Form"
                class="mr-1"
                @click="updateCustom(row.item.id)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                v-b-tooltip.hover
                title="Delete Form"
                @click="confirmDelete(row.item, $event)"
              >
                <i class="fa fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
      <!-- Modal -->
      <!-- view custom-->
      <b-modal title="View Form" ref="formViewCustom" centered hide-footer>
        <div class="form-container">
          <div id="form-view" class="form-preview"></div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jQuery from "jquery";
let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

let utils = require("@/assets/global/js/utils.js");
export default {
  components: {},
  data: function () {
    return {
      formsList: [],
      formsFields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "enable",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "modified",
          label: "Last Updated",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions" }
      ],

      // table search
      searchTerms: "",

      busy: false,
      options: [],
      selected: [],

      currentUserID: null,

      totalRows: 1,
      currentPage: 1,
      perPage: 10000,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {},
  mounted: function () {
    this.getFormList();
  },
  methods: {
    getFormList: function () {
      let $this = this;
      $this.busy = true;
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/form/all/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (Object.keys(result).length !== 0) {
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined && Array.isArray(result[key])) {
                  validUrl = key;
                  $this.busy = false;
                  break outer_loop;
                }
              }
            }
            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl])
            ) {
              $this.formsList = result[validUrl];
            }
          } else {
            $this.busy = false;
          }
        })
        .catch((err) => {
          $this.busy = false;
        });
    },

    getAllVmsPreferences: function (form_id, enable) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/api/visitorservice/visit_preference/all/";
      const client = $this.$root.getAjaxFetchClient();
      client.getRequest(API_URL).then((data) => {
        let validUrl = "";
        let result = data.result;
        let preferencesList = null;

        if (result != undefined) {
          outer_loop: for (let key in result) {
            if (result[key] != undefined && Array.isArray(result[key])) {
              validUrl = key;
              $this.busy = false;
              break outer_loop;
            }
          }
        }
        if (result[validUrl] != undefined && Array.isArray(result[validUrl])) {
          preferencesList = result[validUrl];
        }

        for (let key2 in preferencesList) {
          let preference = preferencesList[key2];
          for (let key in preference.forms) {
            if (key == form_id) {
              preference.forms[key].enable = enable;
              this.updateVisitPreferences(preference);
            }
          }
        }
      });
    },

    updateVisitPreferences(preference) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit_preference/?visit_preference_id=" +
        preference.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, preference)
        .then((data) => {
          if (data.detail != undefined) {
            //$this.updateFormErrorMessage = data.detail;
          } else {
            $this.$store.dispatch(
              "vmsPreferences/updatePreference",
              preference
            );
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    updateCustom: function (id) {
      this.$router
        .push({ name: "Custom Editor", params: { form_id: id } })
        .catch(() => {});
    },

    confirmDelete: function (form, e) {
      e.preventDefault();
      var $this = this;
      var form_id = form.id;
      this.$bvModal
        .msgBoxConfirm('Confirm delete form "' + form.name + '"?', {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            var API_URL =
              $this.getAPIServerURL +
              "/api/visitorservice/form/?form_id=" +
              form_id;
            const client = $this.$root.getAjaxFetchClient();
            client
              .deleteRequest(API_URL)
              .then((data) => {
                this.getFormList();
                // this.$store.dispatch("psim/deleteForm", form_id);
                this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Deleted form successfully",
                  message_type: "success"
                });

                // TOCLEAN
                // } else {
                //     this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": data.detail, "message_type": "danger"});
                // }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.detail,
                  message_type: "danger"
                });
                // if (err.status == 401) {
                //   $this.$store.dispatch("session/logoutSession");
                // }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    setFormData: function (form_data) {
      let $this = this;
      $this.$refs["formViewCustom"].show();
      $this.$nextTick(() => {
        $("#form-view").formRender({
          dataType: "json",
          formData: form_data
        });
      });
    },

    formStatusHandler: function (data_form) {
      let $this = this;
      let id = data_form.id;
      let action = "";
      if (data_form.enable == false) {
        action = "disable";
      } else {
        action = "enable";
      }
      $this.$bvModal
        .msgBoxConfirm("Confirm " + action + " " + data_form.name + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            let API_URL =
              $this.getAPIServerURL + "/api/visitorservice/form/?form_id=" + id;
            const client = $this.$root.getAjaxFetchClient();

            let post_data = {
              ...data_form
            };

            client
              .putRequest(API_URL, post_data)
              .then((data) => {
                let results = data.result;
                let validUrl = null;

                outer_loop: for (let key in data.result) {
                  validUrl = key;
                  break outer_loop;
                }

                if (results[validUrl] == null) {
                  let update_data = {
                    id: data_form.id,
                    name: data_form.name,
                    index: data_form.index,
                    form_data: data_form.form_data,
                    enable: data_form.enable
                  };
                  $this.$store.dispatch("psim/updateForm", update_data);

                  this.getAllVmsPreferences(update_data.id, update_data.enable);

                  if (data_form.enable == true) {
                    $this.$store.dispatch("session/addGlobalAlertMessage", {
                      message_text:
                        'Enable"' + post_data.name + '" successfully',
                      message_type: "success"
                    });
                  } else {
                    $this.$store.dispatch("session/addGlobalAlertMessage", {
                      message_text:
                        'Disable"' + post_data.name + '" successfully',
                      message_type: "success"
                    });
                  }
                }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.detail,
                  message_type: "danger"
                });
                // if (err.status == 401) {
                //   $this.$store.dispatch("session/logoutSession");
                // }
              });
          } else {
            return (data_form.enable = !data_form.enable);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    }
  }
};
</script>
<style lang="scss">
@import "CustomForm";
</style>
